import React, { forwardRef, ReactElement, Ref, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import { TransitionProps } from "@material-ui/core/transitions";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as userProfileIcon } from './../assets/icons/user-profile-icon.svg';
import { ReactComponent as emailLogoIcon } from './../assets/icons/email-icon.svg';
import { ReactComponent as mobileLogoIcon } from './../assets/icons/new-mobile-icon.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { useStyles } from './UserDetails.styles';
import { checkUserRoleAccess } from '../mocks/users/response.transforms';
import { COLORS } from '../config/theme/baseTheme';
import { endActiveSession } from "./redux/usersSlice";
import { AppState } from "../config/redux/reducers";

import CustomDialog from '../common/Dialog';
import Slide from "@material-ui/core/Slide";
import CustomSnackbar from '../common/CustomSnackbar';
import AppConstants from '../constants/index';
import alertIcon from "./../assets/icons/Alert.svg";

interface UserDetailsProps {
  open: boolean;
  user: any;
  closePopup: () => void;
  handleDialogAction?: (type: string) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UserDetails = (props: UserDetailsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, user, closePopup, handleDialogAction } = props;
  const { endSession } = useSelector(
    (state: AppState) => state.users
  );
  const {
    isCollectionPointManager,
    isDispatcher,
    isOnlyExpressDispatcher,
    isOnlyStandardDispatcher,
    isLastMileViewer,
    isOnlyExpLastMileViewer,
    isOnlyStdLastMileViewer,
  } = useSelector((state: AppState) => state.userLogin);

  const [openEndSessionDialog, setOpenEndSessionDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarMessage = 'Active session has been ended';

  let tableRecord: any = {};
  let hubItems: any = [];

  if (user) {
    tableRecord = user.tableRecord;
    if (user.countryDetails) {
      for (let country in user.countryDetails) {
        let countryHubs = user.countryDetails[country];
        countryHubs.filter((hub: any) => {
          hubItems.push(hub);
          return true;
        })
      }
    }
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }
  const endSessionSelectionDialogPaperPropsRoot = {
    classes: {
      root: classes.endSessionSelectionDialogPaperPropsRoot
    }
  }

  const dialogActionHandler = useCallback(
    (type: string) => {
      handleDialogAction && handleDialogAction(type);
    },
    [handleDialogAction],
  )

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const handleDialogClose = () => {
    closePopup();
  }

  const handleOpenEndSessionDialog = useCallback(
    () => {
      setOpenEndSessionDialog(true);
    }, []
  )

  const handleEndSessionSelectionDialogClose = useCallback(
    () => {
      setOpenEndSessionDialog(false);
    },
    [],
  )

  const handleEndSessionSelection = useCallback(
    (driverId) => {
      dispatch(
        endActiveSession({
          driverId: driverId
        })
      );
      setOpenEndSessionDialog(false);
      setOpenSnackbar(true);
    },
    [dispatch],
  )

  const isEditIconVisible = () =>{
    return !isCollectionPointManager &&
    !isDispatcher &&
    !isOnlyExpressDispatcher &&
    !isOnlyStandardDispatcher &&
    !isLastMileViewer &&
    !isOnlyStdLastMileViewer &&
    !isOnlyExpLastMileViewer
  }

  const fetchUserRoleAccess = () => {
    let canAccess = false;
    const currentRole = sessionStorage.getItem('role') ? JSON.parse(sessionStorage.getItem('role') || "[]")[0] : undefined;
    const selectedRole = tableRecord ? AppConstants.USERS_CONSTANTS.USER_ROLES.filter(role => role.value === tableRecord.role)[0] : undefined;
    if (currentRole && selectedRole) {
      canAccess = checkUserRoleAccess(currentRole, selectedRole);
    }
    if (user && user.status === "AWAITING_EMAIL_VERIFICATION") {
      canAccess = false;
    }
    return canAccess;
  }

  const getHubItems = () => {
    return hubItems && hubItems.length ? hubItems.map((hubItem: any, index: number) => (
      <Grid key={`${hubItem.hubCode} - ${index}`} className="item" item>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn" item>
            <Typography>{hubItem.hubName}</Typography>
          </Grid>
          <Grid className="itemColumn" item>
            <Typography>{hubItem.countryName}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className='headingContainer' container>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography className='titleHeading'>
                    <span className='label'> USER ID : </span>
                    <span className='content'>{`${user.firstname} ${user.lastname}`} </span>
                  </Typography>
                </Grid>
                {isEditIconVisible()
                  ? fetchUserRoleAccess() && (
                      <Grid className='editIcon' item>
                        <SvgIcon className='icon' onClick={() => dialogActionHandler("update")} component={editIcon} />
                      </Grid>
                    )
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className='userDetails' item>
          <Grid className='detailsHeader' container>
            <Grid className='detailsHeaderItem' item>
              <SvgIcon className='profileIcon' viewBox='0 0 140 140' component={userProfileIcon} />
            </Grid>
            <Grid className='detailsHeaderItem' item>
              <Grid container className='rightPanelContainer'>
                <Grid item className='rightPanelItem'>
                  <Typography className='fullname'>{`${user.firstname} ${user.lastname}`}</Typography>
                </Grid>
                <Grid item className='rightPanelItem'>
                  <Grid container alignItems='center'>
                    <SvgIcon className='logo' viewBox='0 0 22 15' component={emailLogoIcon} />
                    <Typography component='span' className='contact mobile'>
                      {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : ""}
                    </Typography>
                    {user?.emailVerified && <CheckCircleOutlineIcon className="contact mobile userIdEmailVerified" />}
                  </Grid>
                </Grid>
                <Grid item className='rightPanelItem'>
                  <Grid container alignItems='center'>
                    <SvgIcon className='logo' viewBox='0 0 12 21' component={mobileLogoIcon} />
                    <Typography component='span' className='contact mobile'>
                      {user.phone ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ""}
                    </Typography>
                  </Grid>
                </Grid>
                {!(isLastMileViewer || isOnlyExpLastMileViewer || isOnlyStdLastMileViewer) && (
                  <Grid item className='rightPanelItem'>
                    <Grid container alignItems='center'>
                      <Button
                        className='endSessionBtn secondary'
                        variant='contained'
                        onClick={() => handleOpenEndSessionDialog()}
                      >
                        {AppConstants.BUTTONS.END_ACTIVE_SESSION}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid className='detailsContent' container>
            <Grid className='contentRow' item>
              <Grid className='contentRowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Role</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.role}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Type</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.type}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Status</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.status}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {tableRecord?.role === "Driver" &&
            <Grid className='contentRow' item>
              <Grid className='contentRowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Type</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.deliveryType}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Vendor</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.vendor}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Vehicle Type</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord && tableRecord.vehicleType}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            }
            <Grid className='contentRow' item>
              <Grid className='contentRowContainer' container>
                <Grid className='contentColumn second' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>User Created Date</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {user && moment.unix(user.creationTime).format(AppConstants.DATE_FORMAT_UI)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>User Modified Date</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {user && moment.unix(user.lastModifiedTime).format(AppConstants.DATE_FORMAT_UI)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Last Login Time</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        { user?.lastLoginTime ? moment.unix(user?.lastLoginTime).format(AppConstants.DATE_TIME_FORMAT) :'--' }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(tableRecord?.role === "Driver" || tableRecord?.role === "QC Executive") && <Grid className='contentRow' item>
              <Grid className='contentRowContainer' container>
              <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>App Version</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {user?.buildVersion ? user?.buildVersion  : '--' }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
            {tableRecord?.role === "Last Mile Dispatcher" &&
            <Grid className='contentRow' item>
              <Grid className='contentRowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Service Type</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{tableRecord?.serviceType && Array.isArray(tableRecord?.serviceType) ? tableRecord?.serviceType?.join(", ") : tableRecord?.serviceType || ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            }
            {!isDispatcher &&
              !isCollectionPointManager &&
              !isOnlyStandardDispatcher &&
              !isOnlyExpressDispatcher &&
              !isLastMileViewer &&
              !isOnlyExpLastMileViewer &&
              !isOnlyStdLastMileViewer && (
                <Grid className='contentRow btnContainer' item>
                  <Grid className='contentRowContainer' container>
                    <Grid className='contentColumn' item>
                      <Button
                        className='resetPasswordBtn secondary'
                        variant='contained'
                        onClick={() => dialogActionHandler("resetPwd")}
                      >
                        {AppConstants.BUTTONS.RESET_PWD}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid className='hubItemsSection' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='consignment-history-content'
              id='consignment-history-header'
              className={classes.accordionSummary}
            >
              <Typography className='header'>Hub Name With Country</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div' className='content'>
                <Grid className='itemsContainer' container direction='column'>
                  <Grid className='item' item>
                    <Grid className='itemContainer header' container>
                      <Grid className='itemColumn' item>
                        <Typography>Hub Name</Typography>
                      </Grid>
                      <Grid className='itemColumn' item>
                        <Typography>Country</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {getHubItems()}
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  };

  const getDialogActions = () => {
    return (
      <></>
    );
  };

  const getEndSessionDialogTitleContent = () => {
    return (
      <>
        <Grid container style={{ padding: "8px" }}>
          <img src={alertIcon} alt="alert-icon" />
          <Typography className={classes.endSessionSelectionDialogTitle}>{AppConstants.USERS_CONSTANTS.END_SESSION}</Typography></Grid>
      </>
    )
  }

  const getEndSessionDialogDetailsContent = () => {
    return (
      <Grid className={classes.endSessionSelectionDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography className={classes.content}>{AppConstants.USERS_CONSTANTS.END_ACTIVE_SESSION_CONTENT}</Typography>
        </Grid>
      </Grid >
    )
  };

  const getEndSessionDialogActions = () => {
    return (
      <Grid className={classes.endSessionDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={handleEndSessionSelectionDialogClose}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => handleEndSessionSelection(tableRecord.userId)}>{AppConstants.BUTTONS.CONFIRM}</Button>
      </Grid>
    )
  }

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={getDialogActions()}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openEndSessionDialog}
        PaperProps={endSessionSelectionDialogPaperPropsRoot}
        title={getEndSessionDialogTitleContent()}
        content={getEndSessionDialogDetailsContent()}
        actions={getEndSessionDialogActions()}
        handleClose={handleEndSessionSelectionDialogClose}
      ></CustomDialog>
      {openSnackbar && endSession ? (
        <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage} />
      ) : null}
    </>
  )
}

export default UserDetails;