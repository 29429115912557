import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px 12px 0px 12px",
      height: "100%"
    },
    container: {
      flexDirection: "column",
      height: "100%",
      '& .userItem': {
        width: '100%',
        '&.heading': {
          paddingBottom: theme.spacing(2.5),
          '& p': {
            fontSize: 24,
            color: COLORS.BLACK,
            fontWeight: 600
          }
        },
        '&.content': {
          backgroundColor: COLORS.WHITE,
          //boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
          borderRadius: theme.spacing(1),
          height: "calc(100vh - 172px)",
          paddingTop: theme.spacing(1.5),
          '& .userIdLink': {
            margin: 'auto 0px',
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12
          },
          '& .userIdEmailVerified':{
            margin: 'auto 4px',
            padding: 0,
            width: '15px',
            height: '15px',
            fill: COLORS.SEA_GREEN,
          }
        }
      }
    },
    statusHeaderContainer: {
      '& .tag': {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        lineHeight: "14px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: 12,
        '&.yellow': {
          color: COLORS.GOLDEN_BELL,
          backgroundColor: COLORS.GOLDEN_YELLOW_LIGHT
        },
        '&.green': {
          color: COLORS.PERSIAN_GREEN_DARK,
          backgroundColor: COLORS.PERSIAN_GREEN_LIGHT
        },
        '&.red': {
          color: COLORS.DARK_RED,
          backgroundColor: COLORS.LIGHT_RED
        },
        '&.gray': {
          color: COLORS.GRAY_DARK,
          backgroundColor: COLORS.ALTO
        },
        '&.black': {
          backgroundColor: COLORS.BLACK,
          color: COLORS.WHITE
        }
      }
    },
    actionsHeader: {
      alignItems: "center",
      '& .actionItem': {
        cursor: "pointer",
        '& .icon': {
          padding: theme.spacing(0.25, 0.5, 0.25, 0.5)
        }
      }
    },
    customDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    dialogDetailsContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .contentItem": {
        "& p": {
          textAlign: "center",
          lineHeight: 1.2,
        },
        "&.heading": {
          padding: theme.spacing(0, 15.75, 5),
          "& p": {
            fontSize: 24,
            fontWeight: 600,
          },
        },
        "&.desc": {
          padding: theme.spacing(0, 2.5),
          "& p": {
            fontSize: 18,
            fontWeight: 400,
            "& .count": {
              fontWeight: 600,
            },
          },
        },
      },
    },
    dialogDetailsActionContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
  }),
);
