import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      "& .headingContainer": {
        flexDirection: "column",
        "& .titleHeading": {
          fontSize: 24,
          fontWeight: 600,
        },
      },
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .MuiOutlinedInput-root": {
        height: 40,
      },
      "& .contentItem": {
        paddingBottom: theme.spacing(3),
        "& .itemContainer": {
          flexDirection: "column",
          "& .itemLabel": {
            paddingBottom: theme.spacing(1),
            alignSelf: "flex-start",
            "& p": {
              fontSize: 14,
              fontWeight: 400,
            },
            "&.divflexStyle": {
              display: 'flex', 
              justifyContent: 'flex-start'
            },
            '&.userIdEmailVerified': {
                margin: 'auto 4px',
                padding: 0,
                width: '15px',
                height: '15px',
                fill: COLORS.SEA_GREEN,
              }
          },
          "& .itemInput": {
            "& .MuiOutlinedInput-input": {
              fontSize: 14,
              fontWeight: 400,
            },
            "& .userIdContainer .input": {
              flexGrow: 1,
              paddingRight: theme.spacing(2),
            },
          },
          "& .phoneItemInput": {
            "& .MuiOutlinedInput-root": {
              height: 40,
              minWidth: "auto",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: 14,
              fontWeight: 400,
            },
            "& .userIdContainer .input": {
              flexGrow: 1,
              paddingRight: theme.spacing(2),
            },
          },
        },
      },
    },
    buttons: {
      width: "100%",
      "& .buttonsContainer": {
        "& .userBtn": {
          "&.secondary": {
            marginRight: theme.spacing(2),
          },
          flexGrow: 1,
        },
      },
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580,
    },
    hubChipContainer : {
      margin: '8px 0px 0px 0px',
      padding: 0,
      display: 'flex',
      justifyContent : 'flex-start',
      width: '100%',
      overflow: 'auto'
    },
    hubChipDiv: {
      margin: '0px 2px 0px 0px',
      padding: '4px',
      minWidth: '125px',
      height: 'auto',
      borderRadius: '16px',
      background: COLORS.ALTO,
      color: COLORS.TEXT_PRIMARY,
      textAlign: 'center',
    }
  })
);
