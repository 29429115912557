import { createStyles, makeStyles, PopoverOrigin, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    searchBox: {
      background: "white",
      overflowY: "auto",
      fontSize: "12px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderBottomLeftRadius: "inherit !important",
        borderBottomRightRadius: "inherit !important",
        borderBottom: "ridge !important",
      },
    },
    customWidthSearch: {
      "& fieldset": {
        maxWidth: "170px",
      },
      "& .MuiIconButton-sizeSmall.MuiIconButton-edgeEnd": {
        marginRight: 20,
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 19,
      overflow: "auto",
    },
    chip: {
      margin: 2,
      height: 17,
      fontSize: 12,
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
      "& .customMenuPropsList": {
        paddingLeft: theme.spacing(5.6),
      },
    },
    menuPropsPaper: {
      maxHeight: 200,
      maxWidth: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none",
    },
    //
    customMenuPropsList: {
      margin: theme.spacing(0, 0, 0, 4),
    },
    customMenuPropsPaper: {
      maxHeight: 400,
      maxWidth: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none",
    },
    hubChipDiv: {
      margin: 0,
      padding: '4px 0px 4px 0px',
      minWidth: '125px',
      height: '30px',
      borderRadius: '16px 0px 0px 0px',
      background: COLORS.ALTO

    }
  })
);

export const anchorOriginDefault: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
