import React, { useState } from "react";

import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { SelectItemProps } from "../CustomHubSelctBox";

interface CustomHubMenuItemsInterface {
  classes: any;
  customCountryRecord?: any;
  items: { [key: string]: Array<SelectItemProps> };
  multiple?: boolean;
  handleDdnChange: any;
  ddnValue: any;
}

const CustomHubMenuItems = (props: CustomHubMenuItemsInterface) => {
  const { classes, customCountryRecord, items, multiple, handleDdnChange, ddnValue } = props;

  const [isOpenStates, setIsOpenStates] = useState<any>({});

  let checker = (arr: any, target: any) => arr.every((v: any) => target.includes(v.value));

  const handleExpandClick = (key: string) => {
    setIsOpenStates((prevStates: any) => ({ ...prevStates, [key]: !prevStates[key] }));
  };

  const getCountryName = (key: string): string => {
    if (customCountryRecord?.length > 0) {
      const country = customCountryRecord.find((countryObject: any) => countryObject.value === key);
      return country ? `${country.name} (All)` : `${key} (All)`;
    }
    return `${key} (All)`;
  };

  return (
    <>
      {items &&
        Object.keys(items)?.length > 0 &&
        Object.keys(items).map((key: string, index: number) => (
          <>
            <MenuItem key={`${key}-${index}`} value={key}>
              <>
                {multiple && <Checkbox size="small" color="primary" checked={items[key]?.length > 0 && checker(items[key], ddnValue)} onClick={(event: any) => handleDdnChange(event, key)} />}
                <ListItemText primary={getCountryName(key)} onClick={() => handleExpandClick(key)} />
                <ExpandMoreIcon onClick={() => handleExpandClick(key)} />
              </>
            </MenuItem>
            {isOpenStates[key] &&
              items[key]?.length > 0 &&
              items[key].map((item: any, itemIndex: number) => (
                <MenuItem
                  key={`${key}-${index}-${item.value}-${itemIndex}`}
                  value={item.value}
                  className={classes.customMenuPropsList}
                  onClick={(event: any) => handleDdnChange(event, key, item.value)}
                >
                  <>
                    {multiple && <Checkbox size="small" color="primary" checked={ddnValue.indexOf(item.value) > -1} />}
                    <ListItemText primary={`${item.value} - ${item.name}`} />
                  </>
                </MenuItem>
              ))}
          </>
        ))}
    </>
  );
};

export default React.memo(CustomHubMenuItems);
